import {Component, Inject, OnInit} from '@angular/core';
import {GeneralService} from "./general.service";
import {environment} from "../environments/environment";
import {allColors} from "../assets/colors";
import {TranslateService} from "@ngx-translate/core";
import * as events from "events";
import {GameService} from "./swagger/api/game.service";
import {debounceTime} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {GameLight} from "./swagger/model/gameLight";
import {NavigationEnd, Router} from "@angular/router";
import {CookieService} from "./cookie.service";
import {AuthService} from "./swagger/api/auth.service";
import {NotificationService} from "./swagger/api/notification.service";
import {Notification} from "./swagger/model/notification";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  notifications: Notification[] = [];
  unreadNotificationCount = 0;
  menuOpen = false;
  isMobile = false;
  valueSearch: string = "";
  showNoResult: boolean = false;
  searching: boolean = false;
  private searchSubject = new Subject<string>();
  public resultSearch: GameLight[];

  constructor(public translate: TranslateService, private authService: AuthService, private router: Router, private cookieService: CookieService,
              public generalService: GeneralService, public gameService: GameService, private notificationService: NotificationService) {
    if (environment.production) {
      console.warn(`🚨 Console output is disabled on production!`);
      console.log = function (): void {
      };
      console.debug = function (): void {
      };
      console.warn = function (): void {
      };
      console.info = function (): void {
      };
    }
    window.addEventListener('message', (event) => {
      console.log(event)

      if (event.data.isInClient) {
        const jwtToken = event;
        this.generalService.setIsinclient(true);
        this.generalService.setJwtClient(jwtToken.data.jwt);
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);

        })
      }
    });
    try {
      if (this.cookieService.getCookie("lang")) {
        this.translate.use(this.cookieService.getCookie("lang")!);
      } else {

        if (this.translate.getBrowserLang()) {
          this.translate.use(this.translate.getBrowserLang()!);
        } else {
          translate.setDefaultLang('en');

        }
      }
    } catch (e) {
      translate.setDefaultLang('en');
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.menuOpen = false;
      }
    });
    this.loadNotifications();
  }

  loadNotifications(): void {
    if (this.generalService.getUserId()) {
      this.notificationService.getUserNotifications(this.generalService.getUserId()).subscribe(data => {
        this.notifications = data.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime());
        this.unreadNotificationCount = this.notifications.filter(n => !n.isRead).length;
      });
    }
  }

  updateUnreadCount(): void {
    this.unreadNotificationCount = this.notifications.filter(n => !n.isRead).length;
  }

  public indieColor: String = "White"
  public passColor: String = "White"
  presentation: boolean = environment.presentation;
  production: boolean = environment.production;
  title = 'indipass-web';

  ngOnInit(): void {
    this.searchSubject.pipe(
      debounceTime(1000)
    ).subscribe(searchValue => {
      if (searchValue && searchValue.length > 0) {
        console.log("searching for " + searchValue)
        this.gameService.getGames(undefined, searchValue, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 5).subscribe(games => {
          this.resultSearch = games;
          if (this.resultSearch.length == 0) {
            this.showNoResult = true;
          }
        }).add(() => {
          this.searching = false;
        });
      }

    });
    this.generalService.getUserInfos()?.id;
    this.checkForMobile();
    window.addEventListener('resize', () => this.checkForMobile());
  }

  logout(): void {
    this.authService.logout().subscribe(data => {
      this.cookieService.delete_cookie("user_infos");
      this.cookieService.delete_cookie("access_token");
      this.generalService.getUserInfos().id
      this.router.navigate(['/home']).then(() => window?.location.reload());
    })
  }

  useLanguage(language: string): void {
    this.cookieService.setCookieWithName("lang", language, 365);
    this.translate.use(language.toString());
  }

  navigateToGame(game: GameLight) {
    this.searchSubject.next("");
    this.valueSearch = "";
    this.resultSearch = [];
    this.router.navigate(['/game/' + game.id]);

  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  //search for games if the search value is more than 2 characters and the last change was more than 500ms ago
  research(searchValue: string) {
    this.searching = true;
    this.showNoResult = false;
    this.resultSearch = [];
    this.searchSubject.next(searchValue);
  }

  checkForMobile() {
    this.isMobile = window.innerWidth <= 1440;
    if (!this.isMobile) {
      this.menuOpen = false;
    }
  }

  randomColor() {
    this.indieColor = allColors[Math.floor(Math.random() * allColors.length)];
    this.passColor = allColors[Math.floor(Math.random() * allColors.length)];

  }

  public readonly environment = environment;
}
